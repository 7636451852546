import React, { useEffect, useState } from 'react';
import {
  AdminBenchmarkReportingWidgetSortType, OrderBy,
  useGetTeacherBmScoresForSchoolSlideoutQuery,
  useGetGradeBmScoresForTeacherSlideoutQuery,
  useGetSchoolBmScoresForGradeSlideoutQuery,
} from '../../../../../generated/graphql';
import usePagination from '../../../../../utils/usePagination';
import { PAGE_SIZE } from '../../../../../variables/constant';

const useAdminBenchmarkSlideoutHook = ({ id, slideOut, tabName, schoolID }: any) => {
  const [isSlideoutOpen, setIsSlideoutOpen] = useState(false);
  const pagination = usePagination('AdminBMSlideOut');
  const [totalCount, setTotalCount] = useState(0);
  const [slideoutData, setSlideoutData] = useState([]);

  const getSortType = (slideType: string) => {
    switch (slideType) {
      case 'school': return AdminBenchmarkReportingWidgetSortType.SchoolName;
      case 'teacher': return AdminBenchmarkReportingWidgetSortType.TeacherName;
      case 'grade': return AdminBenchmarkReportingWidgetSortType.GradePosition;
      default: break;
    }
  };

  const initialQueryInput = {
    limit: PAGE_SIZE,
    page: pagination.page,
    sort: getSortType(slideOut.toLowerCase()),
    orderBy: OrderBy.Asc,
  };

  const [queryInput, setQueryInput] = useState(initialQueryInput);

  const handleSlideoutClose = () => {
    setIsSlideoutOpen(false);
  };


  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
    setQueryInput(prevInput => ({
      ...prevInput,
      page: value as number,
    }));
  };

  const onSortChange = (sortBy: AdminBenchmarkReportingWidgetSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setQueryInput(prevInput => ({
      ...prevInput,
      page: 1,
      sort: sortBy,
      orderBy,
    }));
  };

  const handleSort = (column: AdminBenchmarkReportingWidgetSortType) => () => {
    if (column === queryInput.sort) {
      onSortChange(column, queryInput.orderBy === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };


  const { data: schoolTeachersList, loading: schoolSpecificLoading } = useGetTeacherBmScoresForSchoolSlideoutQuery({
    fetchPolicy: 'network-only',
    variables: {
      schoolId: id,
      ...queryInput,
    },
    skip: tabName !== 'School',
  });

  const { data: teacherGradeList, loading: teacherSpecificLoading } = useGetGradeBmScoresForTeacherSlideoutQuery({
    fetchPolicy: 'network-only',
    variables: {
      teacherId: id,
      schoolId: schoolID,
      sort: queryInput.sort,
      orderBy: queryInput.orderBy,
    },
    skip: tabName !== 'Teacher',
  });

  const { data: gradeSchoolList, loading: gradeSpecificLoading } = useGetSchoolBmScoresForGradeSlideoutQuery({
    fetchPolicy: 'network-only',
    variables: {
      gradeId: id,
      ...queryInput,
    },
    skip: tabName !== 'grade' && slideOut.toLowerCase() !== 'school',
  });

  useEffect(() => {
    if (!schoolSpecificLoading && schoolTeachersList) {
      setTotalCount(schoolTeachersList.adminBenchmarkSchoolsSlideOutComparison.total_count);
      setSlideoutData(schoolTeachersList.adminBenchmarkSchoolsSlideOutComparison.nodes);
    }
  }, [schoolTeachersList, schoolSpecificLoading]);

  useEffect(() => {
    if (!teacherSpecificLoading && teacherGradeList) {
      setTotalCount(teacherGradeList.adminBenchmarkTeacherSlideOutComparison.total_count);
      setSlideoutData(teacherGradeList.adminBenchmarkTeacherSlideOutComparison.nodes);
    }
  }, [teacherGradeList, teacherSpecificLoading]);

  useEffect(() => {
    if (!gradeSpecificLoading && gradeSchoolList) {
      setTotalCount(gradeSchoolList.adminBenchmarkGradeSlideOut.total_count);
      setSlideoutData(gradeSchoolList?.adminBenchmarkGradeSlideOut.nodes);
    }
  }, [gradeSchoolList, gradeSpecificLoading]);


  return {
    loading: schoolSpecificLoading || teacherSpecificLoading || gradeSpecificLoading,
    isSlideoutOpen,
    handleSlideoutClose,
    pagination,
    totalCount,
    queryInput,
    slideoutData,
    handlePageChange,
    handleSort,
    getSortType,
  };
};

export default useAdminBenchmarkSlideoutHook;
