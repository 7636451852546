import axios from 'axios';
import request from 'superagent';
import getPathFromUrl from '../url';
import { sentryErrorLog, sentryMessageLog } from '../sentry';

/** Creates session token header */
export const getHeaders = ({ accessToken, contentType }: any) => {
  const ImpersonatorId = localStorage.getItem('ImitationUser');
  return ({
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...(
        ImpersonatorId && accessToken ? {
          ImpersonatorId: JSON.parse(ImpersonatorId),
        } : {}
      ),
      ...(contentType && { 'Content-Type': contentType }),
    },
  });
};

/** Marks start of a Multipart Upload */
const startUpload = async ({
  fileName, fileType, totalParts, uploadUrl, accessToken,
}: any) => axios.post(`${uploadUrl}/start`, {
  fileName,
  fileType,
  totalParts,
}, getHeaders({ accessToken }));

/** Marks a multipart upload complete */
const completeUpload = ({
  parts, uploadId, key, uploadUrl, headers, fileType,
}: any) => (
  axios.post(`${uploadUrl}/complete`, {
    parts,
    key,
    uploadId,
    fileType,
  }, headers)
);

/** Aborts a multipart upload */
export const abortUpload = ({
  uploadId, key, uploadUrl, accessToken, fileType,
}: any) => (
  axios.post(`${uploadUrl}/abort`, {
    key,
    uploadId,
    fileType,
  }, getHeaders({ accessToken }))
);

/** Uploads a part to s3 */
const uploadToS3 = async ({
  file,
  fileInfo: {
    fileType, fileName, fileSize, filePreview,
  },
  signedUrl,
  handleUpload,
  progressCache,
  number,
}: any) => request
  .put(signedUrl)
  .set('Content-Type', fileType)
  .send(file)
  .on('progress', (e: any) => {
    const blobProgress = Math.round(e.percent);
    // eslint-disable-next-line no-param-reassign
    progressCache[number] = blobProgress * (e.total / fileSize);
    const progress = Object.keys(progressCache).reduce((total, i) => total + progressCache[i], 0);
    handleUpload({
      name: fileName,
      type: fileType,
      preview: filePreview,
      progress: Math.round(progress),
    });
  })
  .retry(2, (err, res) => {
    const statusCode = res?.statusCode ?? 0;
    const willRetry = !!err || (statusCode < 200 || statusCode >= 500);
    if (err) {
      sentryErrorLog(err, [{label: "errorContext", value: `Error uploading chunk, ${willRetry ? 'retrying' : 'will not retry'}`}]);
    } else if (statusCode === 0) {
      sentryMessageLog("Error uploading chunk", "error", [{label: "errorContext", value: `Error uploading chunk, ${willRetry ? 'retrying' : 'will not retry'}`}]);
    }
    return willRetry;
  });

const uploadChunks = async ({
  file,
  handleUpload,
  parts,
  numChunks,
  chunkSize,
}: any) => {
  // getting number of chunks
  const progressCache = {};
  const PartData = {};

  for (let index = 0; index < numChunks; index++) {
    const start = index * chunkSize;
    const end = (index + 1) * chunkSize;
    // splitting a file into a chunk/part
    const blob = index === numChunks - 1 ? file.slice(start) : file.slice(start, end);

    // @ts-ignore
    PartData[index + 1] = { blob };
  }

  const fileInfo = {
    fileType: file.type,
    fileName: file.name,
    fileSize: file.size,
    filePreview: file.preview,
  };
  parts.sort((partA: any, partB: any) => (partA.number > partB.number ? 1 : -1));
  const promisesArray = parts.map(({ number, signedUrl }: any) => (
    // uploads a part in an async manner
    uploadToS3({
      // @ts-ignore
      file: PartData[number].blob,
      fileInfo,
      signedUrl,
      handleUpload,
      progressCache,
      number,
    })));
  const resolvedArray = await Promise.all(promisesArray);
  // joining the uploaded parts data
  return resolvedArray.map((resp, i) => ({
    // @ts-ignore
    etag: resp.headers.etag,
    number: i + 1,
  }));
};

/** Splits file into chunks and uploads them
 * On failure it aborts the request to remove the uploaded parts
 */
const splitFileAndUpload = async ({
  file,
  uploadUrl,
  key,
  uploadId,
  handleUpload,
  accessToken,
  chunkSize,
  numChunks,
  parts,
}: any) => {
  const headers = getHeaders({ accessToken });
  let uploadParts: Array<{number: number, etag: string}> = [];
  try {
    sentryMessageLog('Starting upload to S3', "info", [
      {label: 'numParts', value: `${parts.length}`},
      {label:'uploadId', value: `${uploadId}`},
      {label: 'fileType', value:  file.type},
      {label: 'totalSize', value: `${file.size}`}
    ]);
    uploadParts = await uploadChunks({
      file,
      handleUpload,
      chunkSize,
      numChunks,
      parts,
    }).catch(async (error) => {
      sentryErrorLog(error);
      await abortUpload({
        uploadId, key, uploadUrl, accessToken, fileType: file.type,
      });
      throw error;
    });

    const { data: { url: fileUrl } } = await completeUpload({
      uploadId, key, uploadUrl, headers, parts: uploadParts, fileType: file.type,
    });
    sentryMessageLog('Completed upload to S3', "info", [
      {label: 'numParts', value: `${uploadParts.length}`},
      {label: 'uploadId', value: `${uploadId}`},
      {label: 'fileType', value: file.type},
      {label: 'etags', value: `${uploadParts.map(up => up.etag).join(", ")}`},
    ]);

    handleUpload({
      name: file.name,
      type: file.type,
      url: getPathFromUrl(fileUrl),
      finished: true,
      key,
      progress: 100,
    });

    return {
      name: file.name,
      type: file.type,
      fileUrl,
    };
  } catch (error) {
    sentryMessageLog('Error uploading to S3', "error", [
      {label: 'numParts', value: `${uploadParts.length}`},
      {label: 'uploadId', value: `${uploadId}`},
      {label: 'fileType', value: file.type},
      {label: 'etags', value: `${uploadParts.map(up => up.etag).join(", ")}`},
    ]);
    throw error;
  }
};

const noop = () => {
};

/** Multipart File Uploader for th90 */
export const uploadWithProgress = async ({
  uploadUrl,
  file,
  handleUpload,
  accessToken,
  chunkSize = 5500000, // 5.25M
}: any) => {
  const fileSize = file.size;
  // getting number of chunks
  const numChunks = Math.ceil(fileSize / chunkSize);
  const { data: { uploadId, key, url, parts } } = await startUpload({
    fileName: file.name,
    fileType: file.type,
    uploadUrl,
    accessToken,
    totalParts: numChunks,
  });

  const uploadPromise = splitFileAndUpload({
    file,
    uploadUrl,
    key,
    uploadId,
    url,
    handleUpload,
    accessToken,
    chunkSize,
    numChunks,
    parts,
  });
  return { uploadId, key, promise: uploadPromise };
};

/** This is generic upload for a file */
export const upload = async ({
  uploadUrl,
  file,
  accessToken,
  chunkSize = 5500000, // 5.25M
}: any) => {
  const fileSize = file.size;
  // getting number of chunks
  const numChunks = Math.ceil(fileSize / chunkSize);

  const { data: { uploadId, key, url, parts } } = await startUpload({
    fileName: file.name,
    fileType: file.type,
    uploadUrl,
    accessToken,
    totalParts: numChunks,
  });

  return splitFileAndUpload({
    file,
    uploadUrl,
    key,
    uploadId,
    url,
    handleUpload: noop,
    accessToken,
    chunkSize,
    numChunks,
    parts,
  });
};
