import React, {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Table, TableBody, TableCell, TableRow, TextField,
  Typography
} from '@mui/material';
import { getMountainTimeZone } from '../../utils/timezone';
import { StyledSpan } from '../ImageUpload/UploadFile.styled';
import SuccessIcon from '../Icons/SuccessIcon';
import { boldFontWeight } from '../Common/Common.styled';
import { Cancel } from '@mui/icons-material';
import { DecoupleLogStatusType } from '../../generated/graphql';
import { StatusIcon, StatusIconProps } from '../../pages/DistrictSync/DistrictSyncTable/List';
import {palette} from "../../theme/palette";
import {StyledCircleBtnIcon, StyledDeleteButton} from "../ResourceDetailHeader";
import {DeleteIconNoBorder} from "../Icons/DeleteIcon";

const styles = {
  icon: {
    marginRight: '8px',
    fill: palette.customBlue.primaryBlue,
    '& path': {
      fill: palette.customBlue.primaryBlue,
    },
  },
  iconDisabled: {
    marginRight: '8px',
    fill: palette.customBackground.disabled,
    '& path': {
      fill: palette.customBackground.disabled,
    },
  },
  button: {
    fontWeight: 800,
    minWidth: '180px',
    maxHeight: '50px',
    py: 1.5, px: 2,
    mx: 1,
  },
  buttonDisabled: {
    backgroundColor: palette.customWhite.main,
    color: palette.customBackground.disabled,
  },
  deleteButtonIcon: {
    backgroundColor: palette.customRed.deleteButton,
    color: palette.customWhite.main,
    '&:hover': {
      backgroundColor: palette.customRed.deleteButtonShadow,
      color: palette.customWhite.main,
    },
    '&.MuiButton-root::after': {
      backgroundColor: palette.customRed.deleteButton,
    },
  },
  inputInput: {
    '& .MuiInputBase-input': {
      background: 'none',
      border: 0,
    },
  },
};

const DeleteClassModal = ({ resourceData, openDialog, handleClose, submitDeleteClassRequest }: any) => {
  const [deleteConfirmInputMsg, setDeleteConfirmInputMsg] = useState('');
  const [deleteConfirmInputErrMsg, setDeleteConfirmInputErrMsg] = useState('');
  const deleteDistrictConfirmInputErr = 'Sorry, you must enter the text exactly to confirm.';

  const handleDeleteDistrictClose = () => {
    setDeleteConfirmInputErrMsg('');
    handleClose();
  };

  const handleDeleteDistrictConfirm = async () => {
    if (deleteConfirmInputMsg === '' || deleteConfirmInputErrMsg) {
      setDeleteConfirmInputErrMsg(deleteDistrictConfirmInputErr);
      document.getElementById('delete-district-confirm')?.focus();
    } else {
      await submitDeleteClassRequest?.(resourceData.id);
      setDeleteConfirmInputMsg('');
      handleClose();
    }
  };
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderTop: `12px solid ${palette.customRed.deleteButton}`,
        },
      }}
    >
      <DialogTitle sx={{ paddingTop: 0, textAlign: 'center' }} >
        <Box>
          <StyledCircleBtnIcon
            variant="contained"
            buttonWidth={70}
            buttonHeight={70}
            padding={0}
            sx={{
              ...styles.deleteButtonIcon,
            }}
          >
            <DeleteIconNoBorder
              strokeColor={palette.customRed.deleteButton} strokeWidth={2.5}
              svgWidth='2em' svgHeight='1.5em'
              viewBox='0 0 18 22'
            />
          </StyledCircleBtnIcon>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant='modalTitle1'>
            Delete Class?
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <Box width="80%" textAlign='center'>
            <Typography variant='tableTitleData' sx={{ fontWeight: 600 }}>
              Deleting this class {resourceData.name} will remove active data from the following:
            </Typography>
          </Box>
          <Table aria-label="simple table" sx={{ width: '80%' }}>
            <TableBody>
              <TableRow
                key='schools-count'
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">Teachers</TableCell>
                <TableCell align="left">{resourceData.teacher_count}</TableCell>
              </TableRow>
              <TableRow
                key='classes-count'
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">Students</TableCell>
                <TableCell align="left">{resourceData.student_count}</TableCell>
              </TableRow>
              <TableRow
                key='district-admin-count'
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">Groups</TableCell>
                <TableCell align="left">{resourceData.group_count}</TableCell>
              </TableRow>
              <TableRow
                key='school-admin-count'
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">Assignments</TableCell>
                <TableCell align="left">{resourceData.pt_count}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant='tableTitleData' sx={{ fontWeight: 700, marginTop: 4 }}>
            Type “delete class” to confirm
          </Typography>
          <Box width="80%" textAlign='center'>
            <TextField
              id="delete-district-confirm"
              name="delete-district-confirm"
              aria-describedby="delete-district-confirm"
              fullWidth
              sx={{ ...styles.inputInput }}
              autoComplete="off"
              onChange={(e: any) => {
                !e.target.value || e.target.value === 'delete class' ?
                  setDeleteConfirmInputErrMsg('') :
                  setDeleteConfirmInputErrMsg(deleteDistrictConfirmInputErr);
                setDeleteConfirmInputMsg(e.target.value);
              }}
            />
            {deleteConfirmInputErrMsg && (
              <Typography variant='sFormHelper'>
                {deleteConfirmInputErrMsg}
              </Typography>
            )}
          </Box>
          <DialogActions sx={{ marginTop: 4, paddingX: 0 }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ ...styles.button }}
              onClick={handleDeleteDistrictClose}
            >
              Cancel
            </Button>
            <StyledDeleteButton
              sx={{ ...styles.button }}
              onClick={handleDeleteDistrictConfirm}
            >
              Delete Class
            </StyledDeleteButton>
          </DialogActions>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteClassModal;
