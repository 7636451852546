import React from 'react';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { Redirect, Switch } from 'react-router-dom';
import { toReadableFormat } from '../../../../utils/dateFormat';
import {
  ADMIN_DASHBOARD_READABLE_LAST_UPDATED_OPTIONS_YEAR,
  AdminBenchmarkComparisonTabTooltip,
} from '../../../../variables/constant';
import {
  StyledDistrictWideComparisonsTabs,
  StyledTabs,
} from '../../../AdminAnalytics/DistrictWideComparisons/DistrictWideComparisons.styled';
import ExportToPdf from '../pdfExport';
import useAdminBenchmarkProgressHook from './ProgressScores/admin-benchmark-progress-hook';
import useRole from '../../../../hooks/useRole';
import RoutingPaths from '../../../RoutingPath';
import Layout from '../../../../components/Layout';
import List from '../BenchmarkComparison/ProgressScores/List';
import { useAuthProvider } from '../../../../core/authContext';
import { useImitationProvider } from '../../../../core/imitationContext';
import TypeformEmbed from '../../../../components/TypeformEmbed';
import AppToolTipIcon from '../../../../components/Icons/AppToolTipIcon';
import SwitchToggle from '../../../../components/Switch/SwitchToggle';
import { AdminBenchmarkReportingWidgetSortType, OrderBy } from '../../../../generated/graphql';
import { useTheme } from '@mui/material/styles';

const AdminBenchmarkComparison = () => {
  const theme = useTheme();
  const { isSchoolAdmin } = useRole();

  const {
    loading,
    lastSnowflakeUpdateTimeData,
    tab,
    handleTabChange,
    schoolID,
    districtID,
    comparisonTabs,
    pagination,
    handlePageChange,
    handleSort,
    rowsPerPage,
    setRowsPerPage,
    queryInput,
    onTypeformReady,
    onTypeformSubmit,
    typeformData,
    dismissTypeform,
    showSubmissionCount,
    setShowSubmissionCount,
  } = useAdminBenchmarkProgressHook();

  const { getUser } = useAuthProvider();
  const user = getUser();

  const { getImitationUserType } = useImitationProvider();
  const isNotImitated = !getImitationUserType();

  return (
    <Layout>
      <Switch>
        <Redirect
          from={RoutingPaths.AdminBenchmarkComparison}
          exact
          to={RoutingPaths.AdminBenchmarkComparison + comparisonTabs[0].path}
        />
      </Switch>
      <Box display="flex" flexDirection="column" width="100%">
        <Grid container sx={{ marginBottom: 6.5 }}>
          <Grid item xs={11}>
            <Box>
              <Typography variant="pageTitle">{isSchoolAdmin ? 'School' : 'District'} Benchmark Comparison</Typography>
              {!!lastSnowflakeUpdateTimeData && (
                <Box>
                  <Typography variant="toolTipGreyText">
                    Last Updated:{' '}
                    {toReadableFormat(
                      lastSnowflakeUpdateTimeData,
                      ADMIN_DASHBOARD_READABLE_LAST_UPDATED_OPTIONS_YEAR,
                      'en-US'
                    ).replace('at', '')}{' '}
                    UTC
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={1} mt={2} justifyItems="right">
            <ExportToPdf
              districtId={districtID as string}
              schoolId={schoolID as string}
              lastSnowflakeUpdateTimeData={lastSnowflakeUpdateTimeData}
              isBenchmarkComparison={true}
              tabKey={comparisonTabs[tab].label as string}
              showCountRow={showSubmissionCount}
              comparisonQueryInput={queryInput}
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={7.5}>
            <StyledTabs value={tab} onChange={handleTabChange} textColor="primary" scrollButtons={false}>
              {comparisonTabs.map((tab) => {
                return (
                  <StyledDistrictWideComparisonsTabs
                    key={tab.value as string}
                    classes={{
                      root: `${tab.value as string}`,
                    }}
                    label={
                      <Typography>
                        <Typography component="span" className="tag">
                          {tab.totalCount || '-'}
                        </Typography>
                        <Typography component="span">{tab.label as string}</Typography>
                      </Typography>
                    }
                    disableRipple
                  />
                );
              })}
            </StyledTabs>
          </Grid>
          <Grid item xs={4} justifyItems="right" mt={0.75}>
            <SwitchToggle
              switchLabel="Show Student Submission Count"
              name={'Show Student Submission Count'}
              value={showSubmissionCount}
              handleChange={(ev) => {
                setShowSubmissionCount(ev.target.checked);
              }}
              fontSize={'12px'}
            />
          </Grid>
          <Grid item xs={0.5} justifyItems="right" mr={0} mt={1.5}>
            <Tooltip
              title={
                isSchoolAdmin
                  ? AdminBenchmarkComparisonTabTooltip.schoolAdmin
                  : AdminBenchmarkComparisonTabTooltip.districtAdmin
              }
              placement="left"
              arrow
            >
              <span>
                <Button sx={{ height: 0 }} disabled>
                  <AppToolTipIcon />
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Box>
          <List
            isTableEmpty={!comparisonTabs[tab].totalCount}
            loading={loading!}
            totalCount={comparisonTabs[tab].totalCount || 0}
            pagination={pagination}
            data={comparisonTabs[tab].progressScoreData}
            handlePageChange={handlePageChange}
            handleSort={handleSort}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            sort={(queryInput.sort as AdminBenchmarkReportingWidgetSortType) || undefined}
            order={(queryInput.orderBy as OrderBy) || undefined}
            tabDetails={comparisonTabs[tab]}
            showSubmissionCount={showSubmissionCount}
            schoolId={schoolID}
          />
        </Box>
      </Box>
      {isNotImitated && !loading && !dismissTypeform && typeformData?.show_typeform && (
        <TypeformEmbed
          formId={typeformData?.typeform_form_id}
          onFormSubmittedHandler={onTypeformSubmit}
          onCloseHandler={onTypeformReady}
          typeformLayout="popover"
          hiddenButton={false}
          autoTrigger={false}
          delay={100}
          hiddenFields={{ user_id: user?.id }}
          buttonColor={theme.palette.primary.main}
        />
      )}
    </Layout>
  );
};

export default AdminBenchmarkComparison;
