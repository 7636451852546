import React from 'react';
import { Link, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { StyledTableCellSecondary, StyledTableDataColumn } from '../../../../Admin/admin-tables-styled';
import ArrowUpIcon from '../../../../../components/Icons/ArrowUpIcon';
import DownArrowIcon from '../../../../../components/Icons/DownArrow';
import NilIcon from '../../../../../components/Icons/NilIcon';
import { palette } from '../../../../../theme/palette';
import { TeacherBenchmarkDashboardStylesCSS } from '../../../../TeacherBenchmarksDashboard/teacherbenchmarkdashboard.styled';
import { AdminBenchmarkScoresDataPoint } from '../../../../../generated/graphql';
import { DistrictWideComparisonsStylesCSS } from '../../../../AdminAnalytics/DistrictWideComparisons/DistrictWideComparisons.styled';
import { UnassignedStudents } from '../../../../../variables/constant';
import { tableCellClasses } from '@mui/material/TableCell';

export type ProgressScoresRowProps = {
  progressScore: any;
  showCount: boolean;
  slideOutLink?: boolean;
  triggerSlideOut: Function;
};

const AdminProgressScoresRow = ({
  progressScore,
  showCount,
  slideOutLink,
  triggerSlideOut,
}: ProgressScoresRowProps) => {
  const theme = useTheme();
  const styles = TeacherBenchmarkDashboardStylesCSS(theme);
  const stylesRow = DistrictWideComparisonsStylesCSS(theme);
  const getTagStyleClass = (score: number) => {
    if (score === null || score === undefined) return styles.tagDisabled;
    if (0 < score && score < 1.5) return styles.tagRed;
    if (1.5 <= score && score < 2.5) return styles.tagYellow;
    if (2.5 <= score && score < 3.5) return styles.tagGreen;
    if (3.5 <= score && score < 4.5) return styles.tagBlue;
    if (4.5 <= score && score <= 5) return styles.tagPurple;
    return styles.tagDisabled;
  };

  const speakingScore: AdminBenchmarkScoresDataPoint | undefined = progressScore?.benchmark_data_points?.find(
    (score: any) => score.submission_type === 'Speaking'
  );
  const writingScore: AdminBenchmarkScoresDataPoint | undefined = progressScore?.benchmark_data_points?.find(
    (score: any) => score.submission_type === 'Writing'
  );

  return (
    <>
      <TableRow
        key={progressScore.id}
        sx={{
          ...(showCount && {
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }),
        }}
      >
        <StyledTableDataColumn sx={{ ...stylesRow.width20, ...stylesRow.tableCellTextAlignLeft }}>
          {!progressScore.name && progressScore.id === UnassignedStudents.toLowerCase() ? (
            UnassignedStudents
          ) : slideOutLink && !progressScore.is_disabled ? (
            <Link
              sx={{ textAlign: 'left' }}
              underline="none"
              onClick={() => {
                triggerSlideOut(progressScore);
              }}
            >
              <Typography variant="redirectionLinks">{progressScore.name}</Typography>
            </Link>
          ) : (
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.customBlue.chipDark }}>
              {progressScore.name}
            </Typography>
          )}
        </StyledTableDataColumn>
        <StyledTableDataColumn>
          <Typography
            component="span"
            sx={{ ...styles.tag, ...(getTagStyleClass(speakingScore?.boy_score as number) || {}) }}
          >
            {speakingScore?.boy_score || '-'}
          </Typography>
        </StyledTableDataColumn>
        <StyledTableDataColumn>
          {speakingScore?.boy_to_moy_progress === 'up' ? (
            <ArrowUpIcon />
          ) : speakingScore?.boy_to_moy_progress === 'down' ? (
            <DownArrowIcon />
          ) : (
            <NilIcon />
          )}
        </StyledTableDataColumn>
        <StyledTableDataColumn>
          <Typography
            component="span"
            sx={{ ...styles.tag, ...getTagStyleClass?.(speakingScore?.moy_score as number) }}
          >
            {speakingScore?.moy_score || '-'}
          </Typography>
        </StyledTableDataColumn>
        <StyledTableDataColumn>
          {speakingScore?.moy_to_eoy_progress === 'up' ? (
            <ArrowUpIcon />
          ) : speakingScore?.moy_to_eoy_progress === 'down' ? (
            <DownArrowIcon />
          ) : (
            <NilIcon />
          )}
        </StyledTableDataColumn>
        <StyledTableDataColumn>
          <Typography
            component="span"
            sx={{ ...styles.tag, ...(getTagStyleClass(speakingScore?.eoy_score as number) || {}) }}
          >
            {speakingScore?.eoy_score || '-'}
          </Typography>
        </StyledTableDataColumn>
        <StyledTableDataColumn sx={{ borderRight: `2px solid ${palette.customGrey.iconGrade}` }}>
          <Typography
            sx={{
              color: theme.palette.customBlack.fontBlack,
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 800,
              lineHeight: 'normal',
              letterSpacing: '1px',
            }}
          >
            {speakingScore?.ytd_change || <NilIcon />}
          </Typography>
        </StyledTableDataColumn>
        <StyledTableDataColumn>
          <Typography component="span" sx={{ ...styles.tag, ...getTagStyleClass?.(writingScore?.boy_score as number) }}>
            {writingScore?.boy_score || '-'}
          </Typography>
        </StyledTableDataColumn>
        <StyledTableDataColumn>
          {writingScore?.boy_to_moy_progress === 'up' ? (
            <ArrowUpIcon />
          ) : writingScore?.boy_to_moy_progress === 'down' ? (
            <DownArrowIcon />
          ) : (
            <NilIcon />
          )}
        </StyledTableDataColumn>
        <StyledTableDataColumn>
          <Typography component="span" sx={{ ...styles.tag, ...getTagStyleClass?.(writingScore?.moy_score as number) }}>
            {writingScore?.moy_score || '-'}
          </Typography>
        </StyledTableDataColumn>
        <StyledTableDataColumn>
          {writingScore?.moy_to_eoy_progress === 'up' ? (
            <ArrowUpIcon />
          ) : writingScore?.moy_to_eoy_progress === 'down' ? (
            <DownArrowIcon />
          ) : (
            <NilIcon />
          )}
        </StyledTableDataColumn>
        <StyledTableDataColumn>
          <Typography component="span" sx={{ ...styles.tag, ...getTagStyleClass?.(writingScore?.eoy_score as number) }}>
            {writingScore?.eoy_score || '-'}
          </Typography>
        </StyledTableDataColumn>
        <StyledTableDataColumn>
          <Typography
            sx={{
              color: theme.palette.customBlack.fontBlack,
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 800,
              lineHeight: 'normal',
              letterSpacing: '1px',
            }}
          >
            {' '}
            {writingScore?.ytd_change || <NilIcon />}
          </Typography>
        </StyledTableDataColumn>
      </TableRow>
      {showCount && (
        <TableRow>
          <StyledTableCellSecondary>
            <Typography sx={{ fontSize: '14px', textAlign: 'left' }}>Student Submissions</Typography>
          </StyledTableCellSecondary>
          <StyledTableCellSecondary>
            <Typography sx={{ fontSize: '14px', marginRight: 1 }}>
              {speakingScore?.boy_submission_count || '-'}
            </Typography>
          </StyledTableCellSecondary>
          <TableCell></TableCell>
          <StyledTableCellSecondary>
            <Typography sx={{ fontSize: '14px', marginRight: 1 }}>
              {speakingScore?.moy_submission_count || '-'}
            </Typography>
          </StyledTableCellSecondary>
          <TableCell></TableCell>
          <StyledTableCellSecondary>
            <Typography sx={{ fontSize: '14px', marginRight: 1 }}>
              {speakingScore?.eoy_submission_count || '-'}
            </Typography>
          </StyledTableCellSecondary>
          <TableCell sx={{ borderRight: `2px solid ${palette.customGrey.iconGrade}` }}></TableCell>
          <StyledTableCellSecondary>
            <Typography sx={{ fontSize: '14px', marginRight: 1 }}>
              {writingScore?.boy_submission_count || '-'}
            </Typography>
          </StyledTableCellSecondary>
          <StyledTableCellSecondary></StyledTableCellSecondary>
          <StyledTableCellSecondary>
            <Typography sx={{ fontSize: '14px', marginRight: 1 }}>
              {writingScore?.moy_submission_count || '-'}
            </Typography>
          </StyledTableCellSecondary>
          <TableCell></TableCell>
          <StyledTableCellSecondary>
            <Typography sx={{ fontSize: '14px', marginRight: 1 }}>
              {writingScore?.eoy_submission_count || '-'}
            </Typography>
          </StyledTableCellSecondary>
          <TableCell colSpan={1}></TableCell>
        </TableRow>
      )}
    </>
  );
};

export default AdminProgressScoresRow;
