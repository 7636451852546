import { useTheme } from '@mui/material/styles';
import { BenchmarkScoresWidgetStylesCSS } from '../../../../../TeacherBenchmarksDashboard/IndividualStudentDashboard/BenchmarkScoresWidget/BenchmarkScoresWidget.styled';
import toRound from '../../../../../../utils/toRound';
import { TableRow, Typography } from '@mui/material';
import { StyledTableDataColumn } from '../../../../../Admin/admin-tables-styled';
import React from 'react';
import { AdminBenchmarkGrowthReportChartData } from '../../benchmark-summary-hook';
import { BENCHMARK_TYPE } from '../../../../../../variables/constant';

interface Props {
  rowIndex: number;
  data: AdminBenchmarkGrowthReportChartData;
}

export const getTagStyleClass = (score: number | string, styles: any) => {
  if (0 < score && score < 1.5) return styles.tagRed;
  if (1.5 <= score && score < 2.5) return styles.tagYellow;
  if (2.5 <= score && score < 3.5) return styles.tagGreen;
  if (3.5 <= score && score < 4.5) return styles.tagBlue;
  if (4.5 <= score && score <= 5) return styles.tagPurple;
  return styles.tagDisabled;
};

const ListRow = ({ rowIndex, data }: Props) => {
  const theme = useTheme();
  const styles = BenchmarkScoresWidgetStylesCSS(theme);

  const avgSpeakingScore =
    data?.speakingScore && data?.speakingScore !== 0 ? toRound(data?.speakingScore as number, 1) : '-';
  const avgWritingScore =
    data?.writingScore && data?.speakingScore !== 0 ? toRound(data?.writingScore as number, 1) : '-';
  const hasScoreValue = avgSpeakingScore !== '-' || avgWritingScore !== '-';

  return (
    <TableRow
      key={rowIndex}
      sx={{ ...styles.tableRowHover, ...(rowIndex % 2 !== 0 && styles.tableRowEven), ...styles.tableRowHeight }}
    >
      <StyledTableDataColumn sx={{ ...styles.width5, ...styles.tableCellTextAlignLeft, cursor: 'default' }}>
        <Typography
          variant="redirectionLinks"
          sx={{
            color: hasScoreValue ? theme.palette.customBlack.main : theme.palette.customBlue.chipDark,
            fontWeight: 800,
          }}
        >
          {BENCHMARK_TYPE[data?.benchmarkType.toUpperCase()]}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width15, cursor: 'default' }}>
        <Typography component="span" sx={{ ...styles.tag, ...getTagStyleClass?.(avgSpeakingScore, styles) }}>
          {avgSpeakingScore}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width15, cursor: 'default' }}>
        <Typography component="span" sx={{ ...styles.tag, ...getTagStyleClass?.(avgWritingScore, styles) }}>
          {avgWritingScore}
        </Typography>
      </StyledTableDataColumn>
    </TableRow>
  );
};

export default ListRow;
