import React, { createContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useRole from '../../../hooks/useRole';
import {
  DistrictWideGradeSortType,
  DistrictWideSchoolDetailsSortType,
  OrderBy,
  useMyDistrictQuery,
  useMySchoolQuery,
} from '../../../generated/graphql';
import { DistrictWideComparisonsTabs } from '../../../variables/types';
import { DistrictWideComparisonsStylesCSS } from './DistrictWideComparisons.styled';
import { PAGE_SIZE } from '../../../variables/constant';

export interface DistrictWideQueryInputCommon {
  limit?: number;
  page?: number;
  sort?: any;
  orderBy?: OrderBy;
}

export interface DistrictWideQueryInput extends DistrictWideQueryInputCommon {
  district_id?: string;
  school_id?: string;
}

export interface DistrictWideSchoolSpecificQueryInput extends DistrictWideQueryInputCommon {
  school_id: string;
}

export interface DistrictWideGradeSpecificQueryInput extends DistrictWideQueryInputCommon {
  district_id?: string;
  school_id?: string;
  grade_id: string;
}

interface DistrictWideComparisonsContextValues {
  isSchoolAdmin?: boolean;
  mySchoolData?: any;
  isDistrictAdmin?: boolean;
  myDistrictData?: any;
  loading?: boolean;
  setLoading?: Function;
  widgetLoading?: boolean;
  setWidgetLoading?: Function;
  schoolsDataCount?: number;
  setSchoolsDataCount?: Function;
  schoolsDataQueryInput?: DistrictWideQueryInput;
  setSchoolsDataQueryInput?: Function;
  schoolSpecificDataQueryInput?: DistrictWideSchoolSpecificQueryInput;
  setSchoolSpecificDataQueryInput?: Function;
  gradesDataCount?: number;
  setGradesDataCount?: Function;
  gradesDataQueryInput?: DistrictWideQueryInput;
  setGradesDataQueryInput?: Function;
  gradeSpecificDataQueryInput?: DistrictWideGradeSpecificQueryInput;
  setGradeSpecificDataQueryInput?: Function;
  getTagStyleClass?: (score: number) => Object;
  lastSnowflakeUpdateTimeData?: any;
  getLastSnowflakeUpdateTimeLoading?: boolean;
}

export const DistrictWideComparisonsContext = createContext<DistrictWideComparisonsContextValues>({
  loading: true,
});

interface RouteProp {
  tabKey?: DistrictWideComparisonsTabs;
}

const useDistrictWideComparisons = () => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);
  const getTagStyleClass = (score: number) => {
    if (0 < score && score < 1.5) return styles.tagRed;
    if (1.5 <= score && score < 2.5) return styles.tagYellow;
    if (2.5 <= score && score < 3.5) return styles.tagGreen;
    if (3.5 <= score && score < 4.5) return styles.tagBlue;
    if (4.5 <= score && score <= 5) return styles.tagPurple;
    return styles.tagDisabled;
  };
  const { isSchoolAdmin, isDistrictAdmin } = useRole();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [widgetLoading, setWidgetLoading] = useState(true);
  const [schoolsDataCount, setSchoolsDataCount] = useState(0);
  const [schoolsDataQueryInput, setSchoolsDataQueryInput] = useState<DistrictWideQueryInput>({
    limit: PAGE_SIZE,
    page: 1,
    sort: DistrictWideSchoolDetailsSortType.SchoolName,
    orderBy: OrderBy.Asc,
  });
  const [schoolSpecificDataQueryInput, setSchoolSpecificDataQueryInput] =
    useState<DistrictWideSchoolSpecificQueryInput>();
  const [gradesDataCount, setGradesDataCount] = useState(0);
  const [gradesDataQueryInput, setGradesDataQueryInput] = useState<DistrictWideQueryInput>({
    sort: DistrictWideGradeSortType.GradePosition,
    orderBy: OrderBy.Asc,
  });
  const [gradeSpecificDataQueryInput, setGradeSpecificDataQueryInput] = useState<DistrictWideGradeSpecificQueryInput>();
  const { tabKey } = useParams<RouteProp>();
  const getIndexByTabKey = (tabTag: string) => {
    switch (tabTag) {
      case DistrictWideComparisonsTabs.Schools:
        return 0;
      case DistrictWideComparisonsTabs.Grades:
        return 1;
      default:
        return 0;
    }
  };
  const [tab, setTab] = useState(getIndexByTabKey(tabKey as string));
  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    switch (newValue) {
      case 1:
        history.push(`/admin/analytics/district-wide-comparisons/${DistrictWideComparisonsTabs.Grades}`);
        return;
      case 0:
      default:
        history.push(`/admin/analytics/district-wide-comparisons/${DistrictWideComparisonsTabs.Schools}`);
        return;
    }
  };

  const { data: myDistrictData, loading: myDistrictDataLoading } = useMyDistrictQuery({
    fetchPolicy: 'network-only',
    skip: isSchoolAdmin,
  });

  const { data: mySchoolData, loading: mySchoolDataLoading } = useMySchoolQuery({
    fetchPolicy: 'network-only',
    skip: isDistrictAdmin,
  });

  useEffect(() => {
    setLoading?.((isDistrictAdmin && myDistrictDataLoading) || (isSchoolAdmin && mySchoolDataLoading));
  }, [isDistrictAdmin, isSchoolAdmin, myDistrictDataLoading, mySchoolDataLoading]);

  return {
    getTagStyleClass,
    isSchoolAdmin,
    isDistrictAdmin,
    loading,
    setLoading,
    widgetLoading,
    setWidgetLoading,
    schoolsDataCount,
    setSchoolsDataCount,
    schoolsDataQueryInput,
    setSchoolsDataQueryInput,
    schoolSpecificDataQueryInput,
    setSchoolSpecificDataQueryInput,
    gradesDataCount,
    setGradesDataCount,
    gradesDataQueryInput,
    setGradesDataQueryInput,
    gradeSpecificDataQueryInput,
    setGradeSpecificDataQueryInput,
    tabKey,
    tab,
    setTab,
    handleTabChange,
    myDistrictDataLoading,
    myDistrictData: myDistrictData?.myDistrict,
    mySchoolDataLoading,
    mySchoolData: mySchoolData?.mySchool,
  };
};

export default useDistrictWideComparisons;
