import React from 'react';
import { Box, Grid } from '@mui/material';
import { AdminBenchmarkReportWidgets } from '../../../../variables/constant';
import SubmissionStatusReport from './SubmissionStatusAndGrowthScore/SubmissionStatus';
import GrowthScoreReport from './SubmissionStatusAndGrowthScore/GrowthScore';
import BenchmarkScoreReport from './SubmissionStatusAndGrowthScore/BenchmarkScore';
import IndicatorScoreReport from './IndicatorScore';
import WelcomeDashboardModal from '../../../TeacherBenchmarksDashboard/WelcomeDashboardModal';
import useAdminBenchmarkSummary from './benchmark-summary-hook';

interface Props {
  districtId: string,
  schoolId: string,
}

const BenchmarkSummary = ({ districtId, schoolId }: Props) => {
  const {
    hasGrowthData,
    growthReportData,
    yAxisDomain,
    yAxisTicks,
    benchmarkSummaryWidgetsDataLoading,
    isFilterSelected,
    boySubmissionPieChartData,
    moySubmissionPieChartData,
    eoySubmissionPieChartData,
    boyTotalSubmissions,
    moyTotalSubmissions,
    eoyTotalSubmissions,
    hasBoyStatusData,
    hasMoyStatusData,
    hasEoyStatusData,
    hasStatusData,
  } = useAdminBenchmarkSummary( districtId, schoolId );


  return (
    <Box width="100%" >
      <Grid container spacing={2} sx={{ position: 'relative' }} pt={5}>
        <Box
          display="grid"
          sx={{
            gridGap: 24,
            width: '100%',
          }}
          mb={2}
          ml={2}
        >
          <Grid item>
            <SubmissionStatusReport
              enableTooltip
              alignTitle
              tooltipTitle={AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkSubmissionStatusReport.tooltipText}
              hasBoyStatusData={hasBoyStatusData}
              hasMoyStatusData={hasMoyStatusData}
              hasEoyStatusData={hasEoyStatusData}
              boySubmissionPieChartData={boySubmissionPieChartData || []}
              moySubmissionPieChartData={moySubmissionPieChartData || []}
              eoySubmissionPieChartData={eoySubmissionPieChartData || []}
              boyTotalSubmissions={boyTotalSubmissions}
              moyTotalSubmissions={moyTotalSubmissions}
              eoyTotalSubmissions={eoyTotalSubmissions}
              loading={benchmarkSummaryWidgetsDataLoading}
              hasStatusData={hasStatusData}
            />
          </Grid>
          <Grid item>
            <Box display="flex" flexDirection="row" width="100%">
              <Box pr={0.5} width="50%">
                <GrowthScoreReport
                  hasData={hasGrowthData}
                  loading={benchmarkSummaryWidgetsDataLoading}
                  yAxisDomain={yAxisDomain}
                  yAxisTicks={yAxisTicks}
                  growthReportData={growthReportData}
                  enableTooltip
                  alignTitle
                  tooltipTitle={AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkGrowthReport.tooltipText}
                />
              </Box>
              <Box pl={0.5} width="50%">
                <BenchmarkScoreReport
                  enableTooltip
                  alignTitle
                  tooltipTitle={AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkScores.tooltipText}
                  growthReportData={growthReportData}
                  hasData={hasGrowthData}
                  loading={benchmarkSummaryWidgetsDataLoading}/>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <IndicatorScoreReport
              districtId={districtId}
              schoolId={schoolId}
              enableTooltip
              alignTitle
              yAxisDomain={yAxisDomain}
              yAxisTicks={yAxisTicks}
              tooltipTitle={AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkIndicatorScores.tooltipText}
            />
          </Grid>
          <WelcomeDashboardModal openModal={!isFilterSelected} userRoleText='Admins' backdropSxProps={{ 'marginTop': '20px' }}/>
        </Box>
      </Grid>
    </Box>
  );
};

export default BenchmarkSummary;
