import React, { useEffect } from 'react';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { DistrictWideGradeDetail, DistrictWideGradeSortType } from '../../../../generated/graphql';
import { DistrictWideQueryInput } from '../district-wide-comparisons-hook';
import List from './List';
import { drawAutotableRowBorders, drawAutotableCellChipWithText } from '../../../../utils/pdf';

interface Props {
  pdfDoc: jsPDF;
  posY: number;
  styles: any;
  gradesData: DistrictWideGradeDetail[];
}

export const districtWideGradesPdfTable = ({ pdfDoc, posY, styles, gradesData }: Props) => {
  const districtWideGradesTable = {
    0: {
      key: 'grade_title',
      name: 'Name',
    },
    1: {
      key: 'teacher_count',
      name: 'Teachers',
    },
    2: {
      key: 'student_count',
      name: 'Students',
    },
    3: {
      key: 'total_goals_assigned',
      name: 'Total Goals Assigned',
    },
    4: {
      // `${grade?.total_submissions_completed_count} / ${grade?.total_submissions_count}`
      key: 'pts_completed',
      name: 'PTs Completed',
    },
    5: {
      key: 'speaking_overall_score',
      name: 'Speaking',
    },
    6: {
      key: 'writing_overall_score',
      name: 'Writing',
    },
  };
  const getColVal = (grade: any, colkey: string) => {
    if (colkey === 'pts_completed') {
      return `${grade.total_submissions_completed_count} / ${grade.total_submissions_count}`;
    }
    return grade[colkey];
  };
  const tableHeader: RowInput = [];
  Object.values(districtWideGradesTable).forEach((column: any) => tableHeader.push(column.name));
  const tableRows: RowInput[] = [];
  gradesData.forEach((grade) => {
    const dataRow: RowInput = [];
    Object.values(districtWideGradesTable).forEach((column) => dataRow.push(getColVal(grade, column.key)));
    tableRows.push(dataRow);
  });
  autoTable(pdfDoc, {
    head: [tableHeader],
    body: tableRows,
    theme: 'plain',
    margin: styles.margin,
    headStyles: styles.header,
    styles: styles.rows,
    columnStyles: styles.columns,
    didDrawCell: (data) => {
      drawAutotableRowBorders(data, pdfDoc);
      drawAutotableCellChipWithText(data, pdfDoc, [5, 6]);
    },
    startY: posY,
  });
  // autoTable(pdfDoc, {
  //   html: '#dw-grades-html-table',
  //   theme: 'grid',
  //   includeHiddenHtml: true,
  //   useCss: true,
  //   // tableWidth: 'wrap',
  // });
};

interface HTMLTableProps {
  loading: boolean;
  queryInput: DistrictWideQueryInput;
  districtWideGradesData: DistrictWideGradeDetail[];
  setPdfHTMLRendered: Function;
}

export const DistrictWideGradesHTMLTable = ({
  loading,
  queryInput,
  districtWideGradesData,
  setPdfHTMLRendered,
}: HTMLTableProps) => {
  useEffect(() => setPdfHTMLRendered(true), []);
  return (
    <List
      tableDomId="dw-grades-html-table"
      isTableEmpty={districtWideGradesData.length === 0}
      loading={loading}
      sort={queryInput.sort! as DistrictWideGradeSortType}
      order={queryInput.orderBy!}
      grades={districtWideGradesData as DistrictWideGradeDetail[]}
      handleSort={() => {}}
    />
  );
};
