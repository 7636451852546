import React, { useContext } from 'react';
import { Link, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledTableDataColumn } from '../../../Admin/admin-tables-styled';
import { DistrictWideSchoolDetails } from '../../../../generated/graphql';
import { DistrictWideComparisonsStylesCSS } from '../DistrictWideComparisons.styled';
import { DistrictWideComparisonsContext } from '../district-wide-comparisons-hook';

interface Props {
  rowIndex: number;
  school: DistrictWideSchoolDetails;
  openFlyover: Function;
}

const SchoolRow = ({ rowIndex, school, openFlyover }: Props) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);

  const { getTagStyleClass } = useContext(DistrictWideComparisonsContext);

  const avgSpeakingScore = school?.avg_speaking_score;
  const avgWritingScore = school?.avg_writing_score;

  return (
    <TableRow key={rowIndex} sx={{ ...styles.tableRowHover }}>
      <StyledTableDataColumn sx={{ ...styles.width20, ...styles.tableCellTextAlignLeft }}>
        <Link
          underline="none"
          sx={{ ...styles.tableTypographyTextAlignLeft }}
          component="button"
          onClick={() => {
            openFlyover(school);
          }}
        >
          <Typography variant="redirectionLinks">{school?.school_name}</Typography>
        </Link>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width10 }}>{school?.teachers}</StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width10 }}>{school?.students}</StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width20 }}>{school?.goals_assigned}</StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width20 }}>
        {`${school?.pts_completed} / ${school?.pts_assigned}`}
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width10 }}>
        <Typography component="span" sx={{ ...styles.tag, ...getTagStyleClass?.(avgSpeakingScore) }}>
          {avgSpeakingScore}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={{ ...styles.width10 }}>
        <Typography component="span" sx={{ ...styles.tag, ...getTagStyleClass?.(avgWritingScore) }}>
          {avgWritingScore}
        </Typography>
      </StyledTableDataColumn>
    </TableRow>
  );
};

export default SchoolRow;
