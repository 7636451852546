import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useClass from './class-hook';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import StyledBoxWrapper from '../../../components/StyledCard/StyledCard.styled';
import ResourceDetailHeader from '../../../components/ResourceDetailHeader';
import ResourceDetail from '../../Districts/District/resource-detail';
import SchoolsIcon from '../../../components/Icons/SchoolsIcon';
import TeachersIcon from '../../../components/Icons/TeachersIcon';
import ClassesIcon from '../../../components/Icons/ClassesIcon';
import MessageIcon from '../../../components/Icons/MessageIcon';
import ClockIcon from '../../../components/Icons/ClockIcon';
import { MY_SCHOOL, PROVIDER_TYPE } from '../../../variables/constant';
import { Entity } from '../../../variables/types';
import { SectionCategory } from '../../../generated/graphql';
import AddNewEntity from '../../../components/AddNewEntity';
import { palette } from '../../../theme/palette';
import ListWithTabs from '../../InfoTables';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import { sectionsRoute } from '../../../variables/staticRoutes';
import { SearchContext } from '../../../core/searchContext';
import RoutingPaths from '../../RoutingPath';
import DeleteClassModal from "../../../components/DeleteClassModal";

const styles = {
  link: {
    fontWeight: 700,
    color: palette.customBlue.primaryBlue,
    textDecoration: 'none',
  },
};

const ClassComponent = () => {
  const {
    classDetail,
    loading,
    editClassPermission,
    isSchoolAdmin, classID,
    resourceData,
    submitDeleteClassRequest,
  } = useClass();
  const [viewDeleteClassDetails, setViewDeleteClassDetails] = React.useState(false);
  const handleViewDeleteClassDetails = () => {
    setViewDeleteClassDetails(true);
  };

  const closeViewDeleteClassDetails = () => {
    setViewDeleteClassDetails(false);
  };
  const breadcrumb = [
    sectionsRoute,
    {
      label: classDetail?.name ?? '',
      route: `${sectionsRoute.route}/${classDetail?.id}`,
    }];
  const { setTabSection } = useContext(SearchContext);
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <AppBreadcrumbs  breadcrumb={loading ? [] : breadcrumb} />
      <ResourceDetailHeader
        title={classDetail?.name ?? ''}
        secondaryBtnRedirectionLink={classDetail?.category === SectionCategory.CustomClass && editClassPermission ? `${RoutingPaths.AdminClasses}/${classDetail?.id}/manage` : undefined}
        secondaryBtnLabel={classDetail?.category === SectionCategory.CustomClass && editClassPermission ? 'Manage Students' : undefined}
        handleSecondryBtnRedirectionClick={() => setTabSection?.('')}
        addNewEntity={editClassPermission ? (
          <AddNewEntity
            entity={Entity.Class}
            districtId={classDetail?.districtData?.id}
            stateId={classDetail?.districtData?.state?.id}
            schoolId={classDetail?.school_id}
          />
        ) : undefined}
        primaryBtnRedirectionLink={!editClassPermission ? undefined : `${RoutingPaths.AdminCreateClass}/${classDetail?.id}`}
        PrimaryBtnIcon={PencilIcon}
        primaryBtnLabel="Edit"
        deleteBtnLabel={classDetail?.source === PROVIDER_TYPE.FLASHLIGHT ? "Delete Class" : undefined}
        deleteBtnHandler={handleViewDeleteClassDetails}
      />
      <Box my={2}>
        <StyledBoxWrapper width="30%">
          <ResourceDetail
            label={classDetail?.school || '-'}
            link={`${RoutingPaths.Schools}/${isSchoolAdmin ? MY_SCHOOL : classDetail?.school_id ?? ''}`}
            Icon={SchoolsIcon}
          />
          <ResourceDetail
            label={(
              <>
                {classDetail?.teachers?.map((teacher, index) => (
                  <Typography
                    key={teacher?.id}
                    sx={{ ...styles.link }}
                    component={Link}
                    to={`${RoutingPaths.Teachers}/${teacher?.id}`}
                  >
                    {teacher?.primary_teacher && '*'}
                    {teacher?.teacher_name ?? ''}
                    {index !== classDetail?.teachers?.length - 1 ? ', ' : ''}
                  </Typography>
                ))}
              </>
            )}
            Icon={TeachersIcon}
          />
          <ResourceDetail label={`Class ID: ${classDetail?.section_number || '-'}`} Icon={ClassesIcon} />
          <ResourceDetail label={`Grades: ${classDetail?.grades?.join(', ') || '-'}`} Icon={MessageIcon} />
          <ResourceDetail label={`Period: ${classDetail?.period || '-'}`} Icon={ClockIcon} />
        </StyledBoxWrapper>
        <ListWithTabs sectionType={'classes'} classID={classID} />
      </Box>
      <Loader open={loading} />
      {viewDeleteClassDetails && <DeleteClassModal
        openDialog={viewDeleteClassDetails}
        handleClose={closeViewDeleteClassDetails}
        resourceData={resourceData?.sectionAssociationCount}
        submitDeleteClassRequest={submitDeleteClassRequest}/>}
    </Box>
  );
};

export default React.memo(ClassComponent);
