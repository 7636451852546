import { Box } from '@mui/material';
import React from 'react';
import useProgressScores from './progress-scores-hooks';
import List from './List';
import StyledCard from '../../../components/StyledCard';
import { TeacherBenchmarkDashBoard } from '../../../variables/constant';
import AppTooltip from '../../../components/AppTooltip';
import DebouncedSearchBar from '../../../components/DebounceSearchBar';


const ProgressScores = () => {
  const {
    progressScores, progressScoreDataLoading,
    handlePageChange, handleSort, totalCount,
    handleSearch, order, sort,
    pagination, rowsPerPage,
    setRowsPerPage, searchTerm,
  } = useProgressScores();


  return (
    <Box sx={{ height: '100%', width: '100%', p: 4, backgroundColor: '#F7F7F7' }}>
      <StyledCard
        title={''}
        columnSetting
        isToolTip={true}
        titleAlignment={'left'}
        toolTipComponent={<AppTooltip toolTipText={TeacherBenchmarkDashBoard.ProgressScore.tooltipText} />}
        marginBottom='0px'
        overridePadding={'24px 24px 0 24px'}
      >
        <Box sx={{ width: '449px', alignSelf: 'flex-start', marginTop: '-16px' }}>
          <DebouncedSearchBar
            handleChange={handleSearch}
            searchTerm={searchTerm}
          />
        </Box>
        <Box sx={{ alignSelf: 'flex-start', width: '100%', marginTop: '-16px' }}>
          <List
            isTableEmpty={progressScores?.length === 0}
            loading={progressScoreDataLoading}
            totalCount={totalCount}
            order={order}
            pagination={pagination}
            sort={sort}
            data={progressScores}
            handlePageChange={handlePageChange}
            handleSort={handleSort}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Box>

      </StyledCard >
    </Box>
  );
};
export default ProgressScores;
